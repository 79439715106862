var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"banner"},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../assets/imgs/optimization_algorithm/banner.webp')),expression:"require('../assets/imgs/optimization_algorithm/banner.webp')"}],attrs:{"alt":""}})]),_c('h2',{staticClass:"title"},[_vm._v("营销优化算法")]),_c('p',{staticClass:"introduce"},[_vm._v("\n        敏锐响应游客需求，促进游客消费转化，全方位激活目的地资源价值\n      ")])]),_c('p',{staticClass:"title_h2"},[_vm._v("核心技术")]),_c('span',{staticClass:"introduce_span"},[_vm._v("大数据+AI+IOT融合应用服务")]),_c('div',{staticClass:"algorithm_advantage"},[_c('div',{staticClass:"image_1"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            require('../assets/imgs/optimization_algorithm/gand-up6.png')
          ),expression:"\n            require('../assets/imgs/optimization_algorithm/gand-up6.png')\n          "}]})]),_vm._m(0)]),_c('div',{staticClass:"advantage_data"},[_vm._m(1),_c('div',{staticClass:"image_2"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            require('../assets/imgs/optimization_algorithm/gand-up5.png')
          ),expression:"\n            require('../assets/imgs/optimization_algorithm/gand-up5.png')\n          "}]})])])]),_c('Header'),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center_1"},[_c('p',[_vm._v("算法优势")]),_c('span',[_vm._v("\n          以强大数据处理技术，通过旅游行业的多年积淀，沉淀了大量\n          经过实战锤炼的文旅垂直行业算法集合、完备的文旅知识库体\n          系、精确的文旅行业深度决策树模型和丰富的文旅行业特征标\n          签，具有原创六大文旅大数据场景应用分析模型。\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center_2"},[_c('p',[_vm._v("数据优势")]),_c('span',[_vm._v("\n          基于海量数据来支撑旅游目的地精细化、智能化运营；在数据方面主要通过范围涵盖全网95%以上信息源的互联网文本爬取、挖掘、百万级APP的实时定位获取和沉淀了大量数据，形成资源域、流动域、政府管理机构域、媒体与营销域等十多个可用于文旅行业分析的数据域\n        ")])])
}]

export { render, staticRenderFns }