<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div>
          <img
            v-lazy="require('../assets/imgs/optimization_algorithm/banner.webp')"
            alt=""
          />
        </div>
        <!-- 横幅内容区域 -->
        <h2 class="title">营销优化算法</h2>
        <p class="introduce">
          敏锐响应游客需求，促进游客消费转化，全方位激活目的地资源价值
        </p>
      </div>
      <p class="title_h2">核心技术</p>
      <span class="introduce_span">大数据+AI+IOT融合应用服务</span>
      <!-- 算法优势 -->
      <div class="algorithm_advantage">
        <div class="image_1">
          <img
            v-lazy="
              require('../assets/imgs/optimization_algorithm/gand-up6.png')
            "
          />
        </div>
        <div class="center_1">
          <p>算法优势</p>
          <span>
            以强大数据处理技术，通过旅游行业的多年积淀，沉淀了大量
            经过实战锤炼的文旅垂直行业算法集合、完备的文旅知识库体
            系、精确的文旅行业深度决策树模型和丰富的文旅行业特征标
            签，具有原创六大文旅大数据场景应用分析模型。
          </span>
        </div>
      </div>

      <!-- 数据优势 -->
      <div class="advantage_data">
        <div class="center_2">
          <p>数据优势</p>
          <span>
            基于海量数据来支撑旅游目的地精细化、智能化运营；在数据方面主要通过范围涵盖全网95%以上信息源的互联网文本爬取、挖掘、百万级APP的实时定位获取和沉淀了大量数据，形成资源域、流动域、政府管理机构域、媒体与营销域等十多个可用于文旅行业分析的数据域
          </span>
        </div>
        <div class="image_2">
          <img
            v-lazy="
              require('../assets/imgs/optimization_algorithm/gand-up5.png')
            "
          />
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "optimization_algorithm",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.banner {
  height: 600px;
  color: #fff;
  position: relative;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 3px;
    text-align: left;
    position: absolute;
    left: 361px;
    top: 230px;
  }
  .introduce {
    width: 565px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 361px;
    top: 321px;
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

//版心
.title_h2 {
  font-size: 40px;
  font-weight: 500px;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 24px;
  margin-top: 80px;
}
.introduce_span {
  font-size: 20px;
  font-weight: 400px;
  color: #565656;
  letter-spacing: 1px;
}
.algorithm_advantage {
  margin-top: 60px;
  margin-bottom: 100px;
  display: flex;
  flex: 1;
  justify-content: center;
  .image_1 {
    width: 455px;
    height: 268px;
    display: flex;
    justify-content: center;
    align-items: center;
    // loading大小
    img[lazy="loading"] {
      width: 100px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .center_1 {
    width: 378px;
    text-align: left;
    margin-left: 107px;
  }
  p {
    font-size: 32px;
    font-weight: 400;
    color: #333;
    margin-top: 47px;
    margin-bottom: 16px;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    color: #999;
  }
}
.advantage_data {
  height: 488px;
  display: flex;
  justify-content: center;
  background: #f6f8fb;
  .center_2 {
    width: 378px;
    text-align: left;
    margin-top: 130px;
    p {
      font-size: 32px;
      font-weight: 400;
      color: #333;
      margin-bottom: 12px;
    }
    span {
      font-size: 18px;
      font-weight: 400;
      color: #999;
    }
  }
  .image_2 {
    width: 384px;
    height: 278px;
    margin-top: 80px;
    margin-left: 181px;
    display: flex;
    justify-content: center;
    align-items: center;
    // loading大小
    img[lazy="loading"] {
      width: 100px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
}
</style>